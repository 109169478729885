import { IFacturationInfo } from 'interfaces/FactInfo';
import { IOrder } from 'interfaces/Order';
import { ISerie } from 'interfaces/Serie';
import { CAMPUS, SunatAxios } from './config';

export const postBillingCompany = async (data: IFacturationInfo) => {
  const res = await SunatAxios.post(`/fact/register/${CAMPUS}`, data);
  return res.data;
};

export const getBillingCompany = async () => {
  const res = await SunatAxios.get(`/fact/information/${CAMPUS}`);
  return res.data;
};

export const getSerie = async () => {
  const res = await SunatAxios.get(`/serie/${CAMPUS}`);
  return res.data;
};

export const postSerie = async (data: ISerie) => {
  const res = await SunatAxios.post(`/serie/${CAMPUS}`, data);
  return res.data;
};

export const putSerie = async (idSerie: string, data: ISerie) => {
  const res = await SunatAxios.put(`/serie/${idSerie}`, data);
  return res.data;
};

export const getComprobants = async (type: string, query = '') => {
  const res = await SunatAxios.get(`/order/facturation-pay/${type}/${CAMPUS}${query}`);
  return res.data;
};

export const getPendingComprobants = async (type: string) => {
  const res = await SunatAxios.get(`/order/facturation/${type}/${CAMPUS}`);
  return res.data;
};

export const getCreditNotes = async () => {
  const res = await SunatAxios.get(`/creditnote/${CAMPUS}`);
  return res.data;
};

// SEND TO SUNAT
export const sentIndividual = async (idComprobant: string, data: any) => {
  const res = await SunatAxios.post(`/fact/sendUnit/${idComprobant}/${CAMPUS}`, data);
  return res.data;
};

interface IPostMassiveBallots {
  ids: string[];
}
export const postMassiveBallots = async (data: IPostMassiveBallots) => {
  const res = await SunatAxios.post(`/fact/sendMassive/${CAMPUS}`, data);
  return res.data;
};

interface IGenerateCreditNote {
  tipoDoc: string;
  numDoc: string;
  rznSocial: string;
  address: string;
}
export const generateCreditNote = async (id: string, data: IGenerateCreditNote) => {
  const res = await SunatAxios.post(`/fact/generate-credit-note/${id}/${CAMPUS}`, data);
  return res.data;
};

export const getPendingBilling = async (campus?: string) => {
  if (CAMPUS) {
    const res = await SunatAxios.get(`/order/pending-fact/${campus ?? CAMPUS}`);
    return res.data;
  } else return 0;
};

interface sendMailInformationInt {
  email: string;
  data: IOrder;
  tipoDoc: string;
  url: string;
}
export const sendEmailVoucher = async (data: sendMailInformationInt) => {
  const res = await SunatAxios.post(`/fact/send-email/${CAMPUS}`, data);
  return res.data;
};

export const sendGuiaRemision = async (id: string, data: { serie: string }) => {
  const res = await SunatAxios.post(`/fact/referral-guide/${id}/${CAMPUS}`, data);
  return res.data;
};

// ENVÍO MASIVO

export const getMassiveComprobants = async () => {
  const res = await SunatAxios.get(`/massive/${CAMPUS}`);

  return res.data;
};

// VOLVER A ENVIAR EL MASIVO
export const resendMassiveToSunat = async (id: string) => {
  const res = await SunatAxios.get(`/massive/resend/${id}/${CAMPUS}`);
  return res.data;
};

// REVISAR ESTADO DEL COMPROBANTE
export const checkStatusComprobants = async (id: string) => {
  const res = await SunatAxios.get(`/massive/status-ticket/${id}/${CAMPUS}`);
  return res.data;
};

// REVISAR ESTADO DEL COMPROBANTE
export const acceptMassive = async (id: string) => {
  const res = await SunatAxios.get(`/massive/accept/${id}`);
  return res.data;
};

// ELIMINAR EL ENVÍO MASIVO
export const deleteMassive = async (id: string) => {
  const res = await SunatAxios.delete(`/massive/${id}`);
  return res.data;
};

// VALIDAR FECHAS DEL ENVÍO MASIVO
export const verifyDatesOfMassive = async (id: string) => {
  const res = await SunatAxios.get(`/massive/verify-dates/${id}/${CAMPUS}`);

  return res.data;
};

export const getVoucherData = async (id: string) => {
  const res = await SunatAxios.get(`/voucherData/${id}`);
  return res.data;
};

export const getVoucherDataQuery = async (body: any) => {
  const res = await SunatAxios.post(`/voucherData`, body);
  return res.data;
};
