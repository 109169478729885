import { ITypeGarment } from 'interfaces/TypeGarment';
import { IPrice, IProduct, ITypeProduct } from 'interfaces/Product';
import { CAMPUS, COMPANY, NapNegociosAxios, ReportAxios } from './config';

export const postProducts = async (data: IProduct, type: string) => {
  const res = await ReportAxios.post(`/product/${type}/${CAMPUS}`, data);
  return res.data;
};

export const getDataToProductBatch = async () => {
  const res = await NapNegociosAxios.get(`/product/models/${CAMPUS}`);
  return res.data;
};

export const putProducts = async (data: IProduct, type: string, id: string) => {
  const res = await NapNegociosAxios.put(`/product/${type}/${id}`, data);
  return res.data;
};

export const uploadImageProduct = async (data: any, id: string) => {
  const res = await NapNegociosAxios.put(`/product/image/${id}/${COMPANY}`, data);
  return res.data;
};

export const deleteProducts = async (id: string) => {
  const res = await NapNegociosAxios.delete(`/product/${id}`);
  return res.data;
};

export const importProducts = async (data: Object, type: string) => {
  const res = await ReportAxios.post(`/product/import/${type}/${CAMPUS}`, data);
  return res.data;
};

export const getProducts = async () => {
  const res = await ReportAxios.get(`/product/${CAMPUS}`);
  return res.data;
};

export const getProductsByCategory = async (category: string) => {
  const res = await ReportAxios.get(`/category/products/${category}/${CAMPUS}`);
  return res.data;
};

interface IAssigmentProductsCategory {
  categoryIds: string[];
  freeIds: string[];
}
export const assignProductsCategory = async (category: string, data: IAssigmentProductsCategory) => {
  const res = await NapNegociosAxios.put(`/category/assign/${category}/${CAMPUS}`, data);
  return res.data;
};

export const getKardex = async (product: string) => {
  const res = await ReportAxios.get(`/operation/${product}/${CAMPUS}`);
  return res.data;
};

interface IAssigmentProducts {
  aggregates: any[];
}
export const assigmentProducts = async (data: IAssigmentProducts, product: string) => {
  const res = await NapNegociosAxios.put(`/product/aggregates/${product}`, data);
  return res.data;
};
export const assigmentProductsBySede = async (data: IAssigmentProducts, product: string) => {
  const res = await NapNegociosAxios.put(`/item/aggregates/${product}`, data);
  return res.data;
};

interface IUpdateStatus {
  product: string;
  sedes: {
    _id: string;
    status: boolean;
  }[];
}

export const updateStatusProducts = async (data: IUpdateStatus[]) => {
  const res = await NapNegociosAxios.put(`/item/status/${CAMPUS}`, data);
  return res.data;
};

interface IUpdateCategoryStatus {
  category: string;
  sedes: {
    _id: string;
    status: boolean;
  }[];
}

export const updateStatusCategory = async (data: IUpdateCategoryStatus[]) => {
  const res = await NapNegociosAxios.put(`/item/status-category/${CAMPUS}`, data);
  return res.data;
};

export const disableAllProducts = async () => {
  const res = await NapNegociosAxios.delete(`/item/disabled-all/${CAMPUS}`);
  return res.data;
};

export const getStatusProduct = async (id: string) => {
  const res = await NapNegociosAxios.get(`/item/status/${id}/${CAMPUS}`);
  return res.data;
};

/// TYPES

export const postProductType = async (data: ITypeProduct) => {
  const res = await NapNegociosAxios.post(`/category/${CAMPUS}`, data);
  return res.data;
};

export const getProductType = async () => {
  const res = await NapNegociosAxios.get(`/category/${CAMPUS}`);
  return res.data;
};

export const deleteProductType = async (id: string) => {
  try {
    const res = await NapNegociosAxios.delete(`/category/${id}`);
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const putProductType = async (id: string, data: ITypeProduct) => {
  const res = await NapNegociosAxios.put(`/category/${id}`, data);
  return res.data;
};

// PRICES
export const getProductPrices = async (id: string) => {
  const res = await NapNegociosAxios.get(`/price/${id}`);
  return res.data;
};

export const postProductPrices = async (id: string, data: IPrice) => {
  const res = await NapNegociosAxios.post(`/price/${id}`, data);
  return res.data;
};

export const putProductPrices = async (id: string, data: IPrice) => {
  const res = await NapNegociosAxios.put(`/price/${id}`, data);
  return res.data;
};

export const deleteProductPrices = async (id: string) => {
  const res = await NapNegociosAxios.delete(`/price/${id}`);
  return res.data;
};

export const updatePricesByUnit = async (data: any) => {
  const res = await NapNegociosAxios.put(`/product/update-prices`, data);
  return res.data;
};

export const updatePricesLiquidation = async (data: any) => {
  const res = await NapNegociosAxios.put(`/product/update-liquidation/${COMPANY}`, data);
  return res.data;
};

// MOVIMIENTO DIARIO

export const getDailyMovement = async () => {
  const res = await NapNegociosAxios.get(`/product/inventory/${CAMPUS}`);
  return res.data;
};

interface IPostDailyMovement {
  products: {
    _id: string;
    output: number;
    stock: number;
    finalStock: number;
  }[];
  idsOrders: string[];
}
export const postDailyMovement = async (data: IPostDailyMovement) => {
  const res = await NapNegociosAxios.post(`/operation/massive/${CAMPUS}`, data);
  return res.data;
};

export const getLengthDailyMovement = async () => {
  const res = await NapNegociosAxios.get(`/item/count-inventory/${CAMPUS}`);
  return res.data;
};

// By Sedes
export const getProductsBySede = async (campus?: string) => {
  const res = await ReportAxios.get(`/item/${campus || CAMPUS}`);
  return res.data;
};

// Productos para vender por sede
export const getSaleProductsBySede = async () => {
  const res = await NapNegociosAxios.get(`/item/sale/${CAMPUS}`);
  return res.data;
};

// Productos faltantes por sede
export const getMissingProductsBySede = async () => {
  const res = await NapNegociosAxios.get(`/item/missing/${CAMPUS}`);
  return res.data;
};

export const createMissingProducts = async (data: any) => {
  const res = await NapNegociosAxios.post(`/item/missing/${CAMPUS}`, data);
  return res.data;
};

export const disabledProducts = async (data: any) => {
  const res = await NapNegociosAxios.post(`/item/disabled/${CAMPUS}`, data);
  return res.data;
};

export const putPricesBySede = async (data: any, id: string) => {
  const res = await NapNegociosAxios.put(`/item/${id}`, data);
  return res.data;
};

export const changeStockInItem = async (itemId: string, data: any) => {
  const res = await NapNegociosAxios.put(`/item/change-stock/${itemId}`, data);
  return res.data;
};

export const putStockAndPricesBySede = async (data: any) => {
  const res = await NapNegociosAxios.put(`/item/update-stock-prices/${CAMPUS}`, data);
  return res.data;
};

export const getProductsByCodeWarehouse = async (code: string) => {
  const res = await ReportAxios.get(`/item/warehouse/${code}/${COMPANY}`);
  return res.data;
};

export const getTypeGarment = async () => {
  const res = await NapNegociosAxios.get<ITypeGarment[]>(`/type-garment/${COMPANY}`);
  return res.data;
};

export const postTypeGarment = async (data: any) => {
  const res = await NapNegociosAxios.post(`/type-garment/${COMPANY}`, data);
  return res.data;
};

export const putTypeGarment = async (id: string, data: any) => {
  const res = await NapNegociosAxios.put(`/type-garment/${id}`, data);
  return res.data;
};

export const deleteTypeGarment = async (id: string) => {
  const res = await NapNegociosAxios.delete(`/type-garment/${id}`);
  return res.data;
};
