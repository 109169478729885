import axios from 'axios';
import StorageService from 'config/StorageService';
import { SESSION_USER } from 'config/session';
import { API_NAPNEGOCIOS, API_REPORTES, API_SUNAT } from 'config/api.config';

const SESSION_DATA = StorageService.get(SESSION_USER);

export const USER = SESSION_DATA?.user;
export const TOKEN = SESSION_DATA?.token;
export const CAMPUS = SESSION_DATA?.campus;
export const COMPANY = SESSION_DATA?.company;

export const NapNegociosAxios = axios.create({
  baseURL: API_NAPNEGOCIOS,
  headers: { Authorization: TOKEN },
});

export const ReportAxios = axios.create({
  baseURL: API_REPORTES,
  headers: { Authorization: TOKEN },
});

export const SunatAxios = axios.create({
  baseURL: API_SUNAT,
  headers: { Authorization: TOKEN },
});
