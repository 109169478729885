import { ITransferStock } from 'interfaces/Transfer';
import { CAMPUS, NapNegociosAxios, ReportAxios } from './config';

export const postTransferStock = async (data: ITransferStock) => {
  const res = await NapNegociosAxios.post(`/request-kardex/${CAMPUS}`, data);
  return res.data;
};

export const getTransferStock = async () => {
  const res = await ReportAxios.get(`/request-kardex/${CAMPUS}`);
  return res.data;
};

export const putTransferStock = async (data: ITransferStock, id: string) => {
  const res = await NapNegociosAxios.put(`/request-kardex/${id}`, data);
  return res.data;
};

export const deleteTransferStock = async (id: string) => {
  const res = await NapNegociosAxios.delete(`/request-kardex/${id}`);
  return res.data;
};

export const acceptTransfer = async (idTransfer: string) => {
  const res = await NapNegociosAxios.put(`/request-kardex/accept/${idTransfer}/${CAMPUS}`, {});
  return res.data;
};

export const sendTransfer = async (
  idTransfer: string,
  data: { information: { user: string; phone: string; date: Date | string; comment: string } }
) => {
  const res = await NapNegociosAxios.put(`/request-kardex/send/${idTransfer}/${CAMPUS}`, data);
  return res.data;
};

export const receiveTransfer = async (
  idTransfer: string,
  data: { information: { user: string; phone: string; date: Date | string; comment: string } }
) => {
  const res = await NapNegociosAxios.put(`/request-kardex/receive/${idTransfer}/${CAMPUS}`, data);
  return res.data;
};

export const postCommentTransfer = async (id: string, data: { user: string; message: string }) => {
  const res = await NapNegociosAxios.post(`/request-kardex/message/${id}`, data);
  return res.data;
};

// NEW METHOD
export const getTransferToReceive = async () => {
  const res = await NapNegociosAxios.get(`/request-kardex/toReceive/${CAMPUS}`);
  return res.data;
};

export const getReceivedTransfer = async (data: any) => {
  const res = await NapNegociosAxios.post(`/request-kardex/received/${CAMPUS}`, data);
  return res.data;
};

export const getRecordTransfer = async (data: any) => {
  const res = await NapNegociosAxios.post(`/request-kardex/record/${CAMPUS}`, data);
  return res.data;
};
